function Logo1() {
  return (
    <svg
      width="156"
      height="82"
      viewBox="0 0 156 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M26.3283 9.58304L30.6621 25.3939L37.2717 4.5632C37.8488 2.7665 40.1791 1.80827 42.0847 2.34184C44.0992 2.91895 45.2098 5.19476 44.5674 7.0459L34.7346 36.185C34.1575 37.9817 32.829 39.1468 31.1412 39.1468H30.6076C28.8654 39.1468 27.4389 37.9272 26.9598 36.185L22.6804 19.7425L18.401 36.185C17.9219 37.9272 16.4954 39.1468 14.7532 39.1468H14.2196C12.5318 39.1468 11.2034 37.9817 10.6263 36.185L0.793446 7.0459C0.161882 5.19476 1.27258 2.91895 3.27616 2.34184C5.18174 1.80827 7.512 2.7665 8.08912 4.5632L14.6988 25.3939L19.0326 9.58304C19.4573 7.99324 20.6768 7.0459 22.528 7.0459H22.8437C24.6949 7.0459 25.9145 7.99324 26.3391 9.58304H26.3283Z"
          fill="url(#paint0_linear_2_81)"
        />
        <path
          id="Vector_2"
          d="M66.3128 37.1323C63.8301 38.7766 60.7049 39.3537 57.7976 39.3537C50.7088 39.3537 44.8941 34.2249 44.8941 26.4502C44.8941 18.6754 50.7088 13.71 57.7976 13.71C64.8863 13.71 70.4397 18.8932 70.4397 26.6679C70.4397 27.9855 69.3291 28.9438 68.1639 28.9438H52.2442C52.6144 31.7967 55.7395 33.0163 58.3311 33.0163C59.812 33.0163 61.0861 32.9074 62.6105 31.8511C64.2983 30.686 66.2039 31.0018 67.2601 32.2214C68.6322 33.8112 68.1095 36.087 66.3128 37.1432V37.1323ZM63.0352 24.3377C62.665 21.3214 60.6069 19.8405 57.852 19.8405C55.0971 19.8405 52.9846 21.2125 52.3531 24.3377H63.0352Z"
          fill="url(#paint1_linear_2_81)"
        />
        <path
          id="Vector_3"
          d="M89.7896 13.71C96.0834 13.71 101.789 18.316 101.789 26.5046C101.789 34.6931 96.0834 39.3537 89.7896 39.3537C86.9911 39.3537 83.9748 37.9816 82.385 35.3901V35.8692C82.3306 37.6659 80.6428 39.1468 78.6828 39.1468C76.7227 39.1468 74.9805 37.7203 74.9805 35.8692V3.76829C74.9805 1.91715 76.6683 0.490692 78.6828 0.490692C80.6972 0.490692 82.3306 1.9716 82.385 3.76829V17.6736C84.4975 14.396 88.1998 13.71 89.7896 13.71ZM88.3631 20.3196C85.0311 20.3196 82.3306 23.2814 82.3306 26.559C82.3306 29.8366 85.0311 32.7985 88.3631 32.7985C92.0654 32.7985 94.1778 29.8366 94.1778 26.559C94.1778 23.2814 92.0654 20.3196 88.3631 20.3196Z"
          fill="url(#paint2_linear_2_81)"
        />
        <path
          id="Vector_4"
          d="M2.85148 69.157C4.90951 68.3621 6.86955 69.4727 7.61 71.0081C8.4049 72.6523 10.2016 74.449 13.4792 74.449C16.7568 74.449 18.7713 72.9681 18.8257 70.5834C18.8802 68.9392 18.1397 67.5127 14.4374 66.4565L10.0491 65.2369C2.01303 62.7542 0.630124 58.1481 0.630124 54.8705C0.630124 48.3698 6.18353 43.9815 13.1634 43.9815C18.401 43.9815 22.3102 46.2573 24.2702 50.0576C25.1631 51.7998 24.4336 53.4985 23.1051 54.337C21.1995 55.5566 19.5117 55.023 18.2377 53.4332C16.7023 51.4732 14.6988 50.896 13.0545 50.896C10.354 50.896 8.35045 52.4858 8.35045 54.6528C8.35045 55.6001 8.72069 57.3532 12.2596 58.355L16.5935 59.6291C25.4245 62.1662 26.6985 67.1425 26.5896 70.627C26.4807 77.8682 19.9255 81.3091 13.4792 81.3091C7.03288 81.3091 2.37237 78.184 0.78257 73.4799C0.205451 71.6288 1.04391 69.8321 2.8406 69.1461L2.85148 69.157Z"
          fill="#010101"
        />
        <path
          id="Vector_5"
          d="M43.8923 74.1768C45.2098 74.1768 46.4839 73.8066 47.5401 73.2295C48.8577 72.489 51.0355 72.1732 52.3531 73.4908C53.834 74.9717 53.6271 77.6722 51.8739 78.8809C49.6526 80.4707 46.8541 81.3091 43.8923 81.3091C36.8035 81.3091 30.3572 76.3437 30.3572 68.569V68.4056C30.3572 60.6309 36.8035 55.6655 43.8923 55.6655C46.8541 55.6655 49.6526 56.5148 51.8739 58.0937C53.6162 59.3133 53.834 62.0029 52.3531 63.4838C51.0355 64.8014 48.8577 64.4856 47.5401 63.7451C46.4839 63.168 45.2098 62.7978 43.8923 62.7978C40.7236 62.7978 37.8597 64.9647 37.8597 68.4056V68.569C37.8597 72.0099 40.7127 74.1768 43.8923 74.1768Z"
          fill="#010101"
        />
        <path
          id="Vector_6"
          d="M75.0349 67.6761C75.0349 64.4529 72.7047 62.8087 70.4833 62.8087C68.2619 62.8087 65.6159 64.0282 65.6159 67.7741V77.8246C65.6159 79.6758 63.9281 81.1022 61.9136 81.1022C59.8992 81.1022 58.2658 79.6213 58.2114 77.8246V45.7238C58.2658 43.9271 59.9536 42.4462 61.9136 42.4462C63.8737 42.4462 65.6159 43.8726 65.6159 45.7238V59.9993C66.6177 56.9286 70.1675 55.6655 72.4869 55.6655C78.9876 55.6655 82.4286 59.9448 82.4286 67.4038V77.8246C82.4286 79.6758 80.7408 81.1022 78.7263 81.1022C76.7118 81.1022 75.0785 79.6213 75.024 77.8246V67.6761H75.0349Z"
          fill="#010101"
        />
        <path
          id="Vector_7"
          d="M87.2524 68.4165C87.2524 60.6417 93.0127 55.6763 100.21 55.6763C107.408 55.6763 113.223 60.6526 113.223 68.4165C113.223 76.1804 107.408 81.32 100.21 81.32C93.0127 81.32 87.2524 76.1913 87.2524 68.4165ZM105.818 68.4165C105.818 65.0845 103.281 62.8087 100.21 62.8087C97.1397 62.8087 94.657 65.0845 94.657 68.4165C94.657 71.7486 97.1941 74.1768 100.21 74.1768C103.227 74.1768 105.818 71.7921 105.818 68.4165Z"
          fill="#010101"
        />
        <path
          id="Vector_8"
          d="M117.023 68.4165C117.023 60.6417 122.783 55.6763 129.981 55.6763C137.179 55.6763 142.993 60.6526 142.993 68.4165C142.993 76.1804 137.179 81.32 129.981 81.32C122.783 81.32 117.023 76.1913 117.023 68.4165ZM135.589 68.4165C135.589 65.0845 133.052 62.8087 129.981 62.8087C126.91 62.8087 124.428 65.0845 124.428 68.4165C124.428 71.7486 126.965 74.1768 129.981 74.1768C132.997 74.1768 135.589 71.7921 135.589 68.4165Z"
          fill="#010101"
        />
        <path
          id="Vector_9"
          d="M155.516 77.8246C155.516 79.6758 153.828 81.1022 151.814 81.1022C149.799 81.1022 148.166 79.6213 148.111 77.8246V45.7238C148.166 43.9271 149.854 42.4462 151.814 42.4462C153.774 42.4462 155.516 43.8726 155.516 45.7238V77.8246Z"
          fill="#010101"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_81"
          x1="22.6804"
          y1="2.25472"
          x2="22.6804"
          y2="36.7947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#19BCEF" />
          <stop offset="1" stop-color="#238ACA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_81"
          x1="57.6669"
          y1="-49"
          x2="57.6669"
          y2="36.7947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#19BCEF" />
          <stop offset="1" stop-color="#238ACA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_81"
          x1="88.3849"
          y1="2.25472"
          x2="88.3849"
          y2="36.7947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#19BCEF" />
          <stop offset="1" stop-color="#238ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo1;
