import { useTranslation } from "react-i18next";
import Logo1 from "./Logo1";
import Logo2 from "./Logo2";

const Logo: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <div className="flex justify-center items-end mt-[50px]">
      <Logo2 />
      <Logo1 />
    </div>
  );
};

export default Logo;
