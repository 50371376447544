function Logo2() {
  return (
    <svg
      width="187"
      height="131"
      viewBox="0 0 187 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M52.3187 128.476L52.3285 128.481C60.5249 132.555 70.4721 129.213 74.5461 121.017L104.984 59.781C109.058 51.5846 105.716 41.6375 97.5196 37.5634L97.5099 37.5585C89.3135 33.4845 79.3663 36.8263 75.2922 45.0226L44.8546 106.258C40.7806 114.455 44.1224 124.402 52.3187 128.476Z"
          fill="#313131"
        />
        <path
          id="Vector_2"
          d="M52.3187 128.476L52.3285 128.481C60.5249 132.555 70.4721 129.213 74.5461 121.017L104.984 59.781C109.058 51.5846 105.716 41.6375 97.5196 37.5634L97.5099 37.5585C89.3135 33.4845 79.3663 36.8263 75.2922 45.0226L44.8546 106.258C40.7806 114.455 44.1224 124.402 52.3187 128.476Z"
          fill="url(#paint0_linear_2_91)"
        />
        <path
          id="Vector_3"
          d="M160.465 46.0715C169.793 46.0715 177.354 39.3486 177.354 31.0555C177.354 22.7624 169.793 16.0396 160.465 16.0396C151.138 16.0396 143.576 22.7624 143.576 31.0555C143.576 39.3486 151.138 46.0715 160.465 46.0715Z"
          fill="url(#paint1_linear_2_91)"
        />
        <path
          id="Vector_4"
          opacity="0.25"
          d="M160.727 35.9665C155.326 37.197 150.23 38.0245 145.798 38.4492C146.429 39.4292 147.159 40.3439 148.008 41.1715C152.037 40.8012 156.709 40.0608 161.652 38.9283C167.532 37.5781 172.835 35.9121 176.973 34.1916C177.213 33.1789 177.343 32.1336 177.343 31.0664C177.343 31.0011 177.343 30.9467 177.343 30.8922C172.792 32.7325 167.064 34.5292 160.727 35.9774V35.9665Z"
          fill="#010101"
        />
        <path
          id="Vector_5"
          d="M150.36 16.5513C149.957 16.5513 149.554 16.3553 149.315 15.996C148.923 15.4189 149.064 14.624 149.642 14.232C152.092 12.5551 154.966 11.477 157.928 11.1286C159.3 10.9653 160.694 10.9544 162.055 11.0959C162.752 11.1722 163.264 11.7928 163.188 12.4897C163.111 13.1866 162.491 13.6875 161.794 13.6222C160.618 13.5024 159.42 13.5133 158.233 13.6549C155.663 13.9597 153.191 14.8853 151.079 16.3336C150.861 16.486 150.611 16.5513 150.36 16.5513Z"
          fill="url(#paint2_linear_2_91)"
        />
        <path
          id="Vector_6"
          d="M160.095 48.086C153.496 48.086 147.333 44.5688 144.001 38.7214L142.977 36.9247L145.036 36.8158C149.609 36.5872 155.304 35.7269 161.086 34.4094C166.966 33.0591 172.563 31.3169 176.842 29.4766L178.737 28.6599L178.606 30.718C178.051 39.6361 171.18 46.8773 162.284 47.9444C161.543 48.0315 160.814 48.0751 160.084 48.0751L160.095 48.086ZM147.366 39.2114C150.763 43.6977 156.295 46.1042 162.001 45.4291C168.97 44.5906 174.501 39.3639 175.819 32.6453C171.735 34.2351 166.803 35.716 161.663 36.8921C156.556 38.0572 151.667 38.8521 147.377 39.2114H147.366Z"
          fill="#010101"
        />
        <path
          id="Vector_7"
          d="M142.248 39.451C138.785 39.451 134.092 38.9936 133.471 36.2496C132.916 33.8322 135.812 31.2951 142.335 28.4966L144.077 27.7452L144.11 29.6399C144.11 30.2279 144.154 30.8159 144.219 31.4257C144.469 33.5709 145.144 35.5962 146.212 37.4583L147.235 39.255L145.177 39.3638C144.491 39.3965 143.446 39.451 142.248 39.451ZM141.682 31.5782C136.88 33.854 135.921 35.3785 135.954 35.6833C136.063 35.9882 137.631 36.9465 143.086 36.8811C142.379 35.2369 141.91 33.5055 141.693 31.7089C141.693 31.6544 141.693 31.6108 141.682 31.5673V31.5782Z"
          fill="url(#paint3_linear_2_91)"
        />
        <path
          id="Vector_8"
          d="M175.939 32.6344L176.08 30.5655C176.145 29.5746 176.124 28.6164 176.004 27.6581C175.797 25.9159 175.318 24.2499 174.588 22.7145L173.772 20.9832L175.688 20.9069C182.592 20.6347 186.207 21.6474 186.751 24.043C187.318 26.4712 184.399 29.0193 177.844 31.8286L175.939 32.6453V32.6344ZM177.616 23.4005C178.062 24.6637 178.367 25.9921 178.53 27.3533C178.585 27.7888 178.617 28.2353 178.639 28.6817C183.376 26.4277 184.301 24.925 184.28 24.6092C184.182 24.3261 182.744 23.4005 177.616 23.4005Z"
          fill="url(#paint4_linear_2_91)"
        />
        <path
          id="Vector_9"
          d="M143.391 13.7637C143.391 13.7637 150.807 6.08697 156.872 6.08697C159.551 6.08697 162.088 6.74032 164.309 7.90544C165.354 8.44989 166.628 8.21033 167.412 7.32832L168.991 5.53163C169.688 4.73673 169.253 3.48449 168.218 3.29938L150.491 0.0217797C150.055 -0.0544436 149.598 0.0762249 149.282 0.392007L136.847 12.7946C136.063 13.5677 136.488 14.9071 137.577 15.0922L140.321 15.5713H140.408C141.246 15.6367 142.063 15.2664 142.585 14.6022L143.391 13.7529V13.7637Z"
          fill="#19BCEF"
        />
        <path
          id="Vector_10"
          d="M143.315 16.5404C143.315 16.5404 151.46 8.11231 158.113 8.11231C161.064 8.11231 163.83 8.83099 166.28 10.105C167.423 10.7039 168.828 10.4426 169.688 9.47344L171.42 7.50253C172.182 6.6314 171.714 5.25938 170.57 5.05249L151.101 1.44822C150.622 1.36111 150.121 1.51355 149.772 1.862L136.117 15.4733C135.257 16.3226 135.725 17.7927 136.912 17.9996L139.929 18.5222H140.027C140.952 18.5876 141.845 18.1956 142.422 17.466L143.304 16.5404H143.315Z"
          fill="#010101"
        />
        <path
          id="Vector_11"
          d="M170.57 5.05253L151.101 1.44826C150.828 1.39381 150.556 1.42648 150.317 1.52448L152.712 7.60056C153.061 8.48257 154.03 8.9508 154.934 8.6568C156.001 8.30835 157.079 8.10146 158.113 8.10146C161.064 8.10146 163.83 8.82013 166.28 10.0942C167.423 10.693 168.828 10.4317 169.688 9.46259L171.42 7.49167C172.182 6.62055 171.714 5.24853 170.57 5.04164V5.05253Z"
          fill="#313131"
        />
        <path
          id="Vector_12"
          d="M161.228 17.1503C166.051 17.1503 170.418 18.8707 173.608 21.6365C170.516 18.2174 165.779 16.0287 160.454 16.0287C151.133 16.0287 143.565 22.7472 143.565 31.0447C143.565 31.11 143.565 31.1753 143.576 31.2516C144.513 23.3243 152.048 17.1503 161.217 17.1503H161.228Z"
          fill="#5ECBED"
        />
        <path
          id="Vector_13"
          d="M144.393 39.3965L144.001 38.7214C142.76 36.5545 141.987 34.2025 141.693 31.7198C141.616 31.012 141.573 30.3477 141.562 29.6835C141.518 25.1428 143.152 20.7545 146.157 17.3136C146.614 16.78 147.42 16.7365 147.954 17.1938C148.487 17.6511 148.531 18.4569 148.074 18.9905C145.482 21.9523 144.077 25.7417 144.11 29.6508C144.11 30.228 144.154 30.816 144.219 31.4367C144.437 33.3096 144.981 35.1063 145.841 36.7832C150.273 36.5001 155.641 35.6616 161.086 34.4202C166.639 33.1462 171.931 31.5129 176.113 29.7924C176.124 29.0737 176.091 28.3659 176.004 27.6582C175.797 25.9159 175.318 24.2499 174.588 22.7145C172.813 18.9469 169.547 15.9524 165.627 14.5151C164.973 14.2755 164.636 13.546 164.875 12.8817C165.115 12.2284 165.855 11.8799 166.509 12.1304C171.049 13.8073 174.828 17.27 176.886 21.6365C177.724 23.4223 178.28 25.3497 178.53 27.3642C178.661 28.4639 178.694 29.6073 178.617 30.7506L178.563 31.5238L177.844 31.8287C173.423 33.7233 167.674 35.52 161.652 36.9029C155.652 38.275 149.946 39.1352 145.166 39.3748L144.382 39.4183L144.393 39.3965Z"
          fill="url(#paint5_linear_2_91)"
        />
        <path
          id="Vector_14"
          d="M105.824 106.303C101.793 114.527 105.19 124.461 113.412 128.49C121.634 132.52 131.567 129.119 135.597 120.895L157.321 76.5722C161.352 68.3478 157.954 58.414 149.732 54.3844C141.511 50.3548 131.578 53.7555 127.547 61.9799L105.824 106.303Z"
          fill="url(#paint6_linear_2_91)"
        />
        <path
          id="Vector_15"
          d="M32.103 19.5382C28.0721 11.3138 18.1394 7.91318 9.91771 11.9427C1.69599 15.9723 -1.70135 25.9061 2.32953 34.1305L44.8557 120.899C48.8866 129.123 58.8193 132.524 67.041 128.494C75.2627 124.465 78.66 114.531 74.6292 106.307L32.103 19.5382Z"
          fill="url(#paint7_linear_2_91)"
        />
        <path
          id="Vector_16"
          d="M5.55783 34.9211C1.43089 26.5039 4.18581 16.5187 11.6012 11.281C11.0241 11.4879 10.4579 11.7166 9.90256 11.9888C1.68133 16.0178 -1.71605 25.9486 2.3129 34.1698L44.8455 120.945C46.2828 123.874 48.4715 126.182 51.0631 127.761L5.55783 34.9211Z"
          fill="url(#paint8_linear_2_91)"
        />
        <path
          id="Vector_17"
          d="M82.9292 37.5484L82.9195 37.5533C74.7231 41.6273 71.3813 51.5745 75.4554 59.7709L105.874 120.968C109.948 129.164 119.895 132.506 128.091 128.432L128.101 128.427C136.297 124.353 139.639 114.406 135.565 106.209L105.147 45.0125C101.073 36.8161 91.1256 33.4743 82.9292 37.5484Z"
          fill="url(#paint9_linear_2_91)"
        />
        <path
          id="Vector_18"
          d="M107.664 120.966L77.2512 59.7699C73.1787 51.5705 76.5217 41.6179 84.7211 37.5454C86.7574 36.5327 88.8916 35.9774 91.0259 35.8467C88.2601 35.6943 85.418 36.2278 82.7611 37.5454C74.5617 41.6179 71.2187 51.5705 75.2912 59.7699L105.704 120.966C108.764 127.13 115.156 130.549 121.624 130.135C115.864 129.819 110.419 126.509 107.664 120.966Z"
          fill="#5ECBED"
        />
        <path
          id="Vector_19"
          d="M140.059 23.4114C140.059 24.4132 139.253 25.219 138.252 25.219C137.25 25.219 136.444 24.4132 136.444 23.4114C136.444 22.8125 137.087 21.005 137.598 19.6329C137.827 19.0341 138.676 19.0341 138.894 19.6329C139.417 21.005 140.048 22.8234 140.048 23.4114H140.059Z"
          fill="#313131"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_91"
          x1="90.1733"
          y1="47.8986"
          x2="60.272"
          y2="116.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_91"
          x1="154.857"
          y1="13.5786"
          x2="167.837"
          y2="54.0205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#19BCEF" />
          <stop offset="1" stop-color="#238ACA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_91"
          x1="137.01"
          y1="5.90186"
          x2="194.439"
          y2="29.6509"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_91"
          x1="127.82"
          y1="28.1372"
          x2="185.249"
          y2="51.8753"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_91"
          x1="135.802"
          y1="8.84189"
          x2="193.23"
          y2="32.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_91"
          x1="132.698"
          y1="16.3444"
          x2="190.116"
          y2="40.0934"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_91"
          x1="145.842"
          y1="55.2678"
          x2="110.507"
          y2="144.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_91"
          x1="63.5647"
          y1="122.449"
          x2="-25.4467"
          y2="-38.1755"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#313131" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2_91"
          x1="-8.12969"
          y1="12.4679"
          x2="98.0275"
          y2="169.531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#767676" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2_91"
          x1="75.1971"
          y1="23.7694"
          x2="139.606"
          y2="149.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#19BCEF" />
          <stop offset="1" stop-color="#238ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo2;
