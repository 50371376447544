import SuccessSvg from "../assets/Success.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function Success() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const animationElements = document.querySelectorAll(".animate-fadeIn");
    animationElements.forEach((element) => {
      element.classList.remove("opacity-0");
      element.classList.add("opacity-100");
    });
  }, []);

  return (
    <div className="mx-auto flex flex-col gap-[50px] items-center justify-center mt-20">
      <img
        src={SuccessSvg}
        alt="Success"
        className="w-[367px] h-[430px] animate-fadeIn opacity-0 transition-opacity duration-1000"
      />
      <h1
        className={`text-[#0b1d38] font-['Inter'] text-[1.375rem] font-medium leading-6 animate-fadeIn opacity-0 transition-opacity duration-1000 delay-500 ${
          i18n.language === "ar"
            ? "rtl text-end font-tajawal"
            : "ltr text-start font-inter"
        }`}
      >
        {t("successMessage")}
      </h1>
    </div>
  );
}

export default Success;

