import React, { useEffect } from "react";
import "./App.css";
import "./i18n";
import Form from "./components/Form";
import LanguageSwitcher from "./components/LanguageSwitcher";
import Logo from "./components/Logo";
import Stars from "./components/Stars";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  // Determine if the current language is RTL
  const isRtl = i18n.language === "ar";

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <div className="flex flex-col items-center gap-12 p-4 relative font-tajawal">
      <div
        className={`w-[525px] h-[525px] rounded-full raduis border-100 border-solid bg-transparent border-[#E2F8FD66] shrink-0 absolute -top-[30%]  -z-10 max-sm:hidden ${
          isRtl ? "left-[10%]" : "right-[12.5%]"
        }`}
      ></div>
      <Stars
        className={`${isRtl ? "hidden" : "absolute top-[10%] left-[3%]"}`}
      />
      <Logo />
      <LanguageSwitcher className="absolute top-[10%] right-[3%]" />
      <Form />
      <div>
        <Toaster
          position={!isRtl ? "bottom-right" : "bottom-left"}
          reverseOrder={true}
          toastOptions={{
            style: {
              marginBottom: "10px",
              direction: isRtl ? "rtl" : "ltr",
            },
          }}
        />
      </div>
    </div>
  );
};

export default App;
