import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { createValidationSchema } from "../schemas/validationSchema";
import MdArrowDropDown from "../assets/MdArrowDropDown.svg";
import SaFlag from "../assets/flags/sa.svg";
import toast from "react-hot-toast";
import Success from "./Success";

const Form: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedJobTitle, setSelectedJobTitle] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleJobTitleChange = (title: string) => {
    setSelectedJobTitle(title);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (formReset) {
      setFormReset(false);
      setSelectedJobTitle(null);
    }
  }, [formReset]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setIsSubmitting(true);
    try {
      const response = await fetch("https://sheetdb.io/api/v1/pw39xii2p22ex", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer n0kkp3i9195lg0imyr2mj5hf1zl6ju3op3ihxecn",
        },
        body: JSON.stringify({
          data: {
            Time: new Date().toISOString(),
            "First name": values.firstName,
            "Last name": values.lastName,
            "Company name": values.companyName,
            "Job title":
              values.jobTitle === "other" ? values.otherJob : values.jobTitle,
            "Phone number": values.phone,
            "Work email": values.workEmail,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      setFormReset(true);
      resetForm();
    } catch (error) {
      console.error("Error:", error);
      toast.error(t("errorMessage"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return showSuccess ? (
    <Success />
  ) : (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        companyName: "",
        jobTitle: "",
        phone: "",
        workEmail: "",
        other: "",
      }}
      validationSchema={createValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, resetForm }) => (
        <FormikForm
          className={`p-4 w-1/2 max-xl:w-full mx-auto flex flex-col gap-[32px]  ${
            i18n.language === "ar"
              ? "rtl text-end font-tajawal"
              : "ltr text-start font-inter"
          }`}
        >
          <div
            className={`flex w-full gap-[24px] ${
              i18n.language === "ar" ? "flex-row-reverse" : ""
            }`}
          >
            <div className="w-full flex flex-col gap-[8px]">
              <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
                {i18n.language !== "ar" ? (
                  <>{t("firstName")} *</>
                ) : (
                  <>* {t("firstName")}</>
                )}
              </label>
              <Field
                type="text"
                name="firstName"
                className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                  i18n.language === "ar" ? "text-end" : "text-start"
                }`}
                placeholder={t("firstName")}
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="w-full flex flex-col gap-[8px]">
              <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
                {i18n.language !== "ar" ? (
                  <>{t("lastName")} *</>
                ) : (
                  <>* {t("lastName")}</>
                )}
              </label>
              <Field
                type="text"
                name="lastName"
                className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                  i18n.language === "ar" ? "text-end" : "text-start"
                }`}
                placeholder={t("lastName")}
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-col gap-[8px] relative">
            <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
              {i18n.language !== "ar" ? (
                <>{t("companyName")} *</>
              ) : (
                <>* {t("companyName")}</>
              )}
            </label>
            <Field
              type="text"
              name="companyName"
              className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
              placeholder={t("companyName")}
            />
            <ErrorMessage
              name="companyName"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex flex-col gap-[8px] relative">
            <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
              {i18n.language !== "ar" ? (
                <>{t("jobTitle")} *</>
              ) : (
                <>* {t("jobTitle")}</>
              )}
            </label>
            <div
              ref={dropdownRef}
              className={`relative ${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              <button
                type="button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className={`border border-[#D1D1DB] bg-white rounded-[8px] py-2 px-3 w-full shadow-sm h-[51px] flex items-center ${
                  i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
                } justify-between`}
              >
                {i18n.language === "ar" && !values.jobTitle ? (
                  <span className="ml-2 text-[#6C6C89]">{t("jobTitle")}</span>
                ) : (
                  <span className="text-[#6C6C89]">
                    {selectedJobTitle
                      ? t(`jobTitles.${selectedJobTitle}`)
                      : t("jobTitle")}
                  </span>
                )}
                <img
                  src={MdArrowDropDown}
                  alt="Dropdown"
                  className="w-[16px] h-[16px]"
                />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0  w-full bg-white border-t-0 border border-[#D1D1DB] rounded-[8px] shadow-lg z-10">
                  {[
                    "founder",
                    "director",
                    "administrator",
                    "teachers",
                    "other",
                  ].map((title) => (
                    <button
                      key={title}
                      type="button"
                      onClick={() => {
                        handleJobTitleChange(title);
                        setFieldValue("jobTitle", title);
                      }}
                      className={`block w-full text-left px-4 py-2 hover:bg-[#E1F5FB] border text-[#6C6C89] ${
                        i18n.language === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      {t(`jobTitles.${title}`)}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <ErrorMessage
              name="jobTitle"
              component="div"
              className="text-red-500 text-sm"
            />
            {values.jobTitle === "other" && (
              <div className="flex flex-col gap-[8px] mt-4 relative">
                <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
                  {t("other")} *
                </label>
                <Field
                  type="text"
                  name="otherJob"
                  className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                    i18n.language === "ar" ? "text-end" : "text-start"
                  }`}
                  placeholder={t("other")}
                />
                <ErrorMessage
                  name="otherJob"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[8px] relative">
            <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
              {i18n.language !== "ar" ? (
                <>{t("phone")} *</>
              ) : (
                <>* {t("phone")}</>
              )}
            </label>
            <div className="relative">
              <Field
                type="number"
                name="phone"
                className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 pl-[95px] w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                  i18n.language === "ar" ? "text-end pr-[90px] ltr " : "text-start rtl"
                }`}
                placeholder={t("phone")}
                maxLength={8}
                onInput={(e: any) => {
                  e.target.value = e.target.value.slice(0, 8);
                }}
                style={{
                  WebkitAppearance: 'none', // Remove spin buttons in WebKit browsers
                  MozAppearance: 'textfield', // Remove spin buttons in Firefox
                }}
              />
              <div
                className={`absolute top-1/2 transform -translate-y-1/2 ${
                  i18n.language === "ar" ? "right-3" : "left-3"
                } flex items-center gap-[8px]`}
              >
                {i18n.language !== "ar" ? (
                  <>
                    <img
                      src={SaFlag}
                      alt="Flag"
                      className="w-[20px] h-[20px]"
                    />
                    {`(+216)`}
                  </>
                ) : (
                  <>
                    {`(+216)`}
                    <img
                      src={SaFlag}
                      alt="Flag"
                      className="w-[20px] h-[20px]"
                    />
                  </>
                )}
              </div>
            </div>
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex flex-col gap-[8px]">
            <label className="block text-[#0B1D38] text-[14px] leading-[24px] font-medium">
              {t("workEmail")}
            </label>
            <Field
              type="email"
              name="workEmail"
              className={`border border-[#D1D1DB] rounded-[8px] py-2 px-3 w-full placeholder:text-[#6C6C89] shadow-sm h-[51px] ${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
              placeholder={t("workEmail")}
            />
            <ErrorMessage
              name="workEmail"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex flex-col gap-[12px] self-stretch">
            <button
              type="submit"
              className="bg-[#01A4E4] h-[48px] flex justify-center items-center gap-[8px] self-stretch text-white font-medium px-4 py-2 hover:opacity-80 duration-300 transition-all w-full rounded-[8px]"
              disabled={isSubmitting}
            >
              {isSubmitting ? <span className="loader"></span> : t("submit")}
            </button>
            <p className="text-[#6C6C89] text-[14px] leading-[24px]">
              {t("privacy")}
            </p>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
