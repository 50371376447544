function Stars({ className }: { className: string }) {
  return (
    <svg
      width="136"
      height="23"
      viewBox="0 0 136 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 1000000818">
        <path
          id="path2420"
          d="M112.337 13.8262H121.941V23H125.915V13.8262H135.519V10.0302H125.915V0.856384H121.941V10.0302H112.337V13.8262Z"
          fill="#D5F7FF"
        />
        <path
          id="path2422"
          d="M74.892 13.8262H84.4962V23H88.47V13.8262H98.0743V10.0302H88.47V0.856384H84.4962V10.0302H74.892V13.8262Z"
          fill="#D5F7FF"
        />
        <path
          id="path2424"
          d="M37.4467 13.8262H47.0509V23H51.0247V13.8262H60.629V10.0302H51.0247V0.856384H47.0509V10.0302H37.4467V13.8262Z"
          fill="#D5F7FF"
        />
        <path
          id="path2426"
          d="M0 13.8262H9.60428V23H13.58V13.8262H23.1844V10.0302H13.58V0.856384H9.60428V10.0302H0V13.8262Z"
          fill="#D5F7FF"
        />
      </g>
    </svg>
  );
}

export default Stars;
