import * as Yup from "yup";

export const createValidationSchema = (t: (key: string) => string) =>
  Yup.object({
    firstName: Yup.string().required(t("validation.firstName_required")),
    lastName: Yup.string().required(t("validation.lastName_required")),
    companyName: Yup.string().required(t("validation.companyName_required")),
    jobTitle: Yup.string().required(t("validation.jobTitle_required")),
    phone: Yup.string()
      .required(t("validation.phone_required"))
      .matches(/^[0-9]+$/, t("validation.phone_digits_only"))
      .max(8, t("validation.phone_max_length"))
      .min(8, t("validation.phone_max_length")),
    workEmail: Yup.string().email(t("validation.workEmail_invalid")),
  });
