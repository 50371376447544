import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Import SVG files
import UsFlag from "../assets/flags/us.svg";
import FrFlag from "../assets/flags/fr.svg";
import SaFlag from "../assets/flags/sa.svg";
import MdArrowDropDown from "../assets/MdArrowDropDown.svg";

interface LanguageSwitcherProps {
  className?: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ className }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Create a ref for the dropdown container
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setIsOpen(false); // Close dropdown after selection
  };

  const getFlag = (lang: string) => {
    switch (lang) {
      case "en":
        return <img src={UsFlag} alt="English" className="w-[24px] h-[24px]" />;
      case "fr":
        return (
          <img src={FrFlag} alt="Français" className="w-[24px] h-[24px]" />
        );
      case "ar":
        return <img src={SaFlag} alt="العربية" className="w-[24px] h-[24px]" />;
      default:
        return null;
    }
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`${className} `} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-3 py-1 bg-white rounded-full hover:bg-[#E1F5FB] transition-all duration-300 h-[46px] gap-[16px] shadow-lg shrink-0"
      >
        <img
          src={MdArrowDropDown}
          alt="MdArrowDropDown"
          className="w-[16px] h-[16px]"
        />
        <span className="text-center text-[14px] font-normal leading-[145%]">
          {i18n.language === "en"
            ? "English"
            : i18n.language === "fr"
            ? "Français"
            : "العربية"}
        </span>
        {getFlag(i18n.language)}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-full bg-white border border-white rounded-2xl shadow-lg">
          <button
            onClick={() => handleLanguageChange("en")}
            className="flex items-center px-4 py-2 w-full text-left hover:bg-[#E1F5FB] transition-all duration-300"
          >
            <img src={UsFlag} alt="English" className="w-[24px] h-[24px]" />
            <span className="ml-2">English</span>
          </button>
          <button
            onClick={() => handleLanguageChange("fr")}
            className="flex items-center px-4 py-2 w-full text-left hover:bg-[#E1F5FB] transition-all duration-300"
          >
            <img src={FrFlag} alt="Français" className="w-[24px] h-[24px]" />
            <span className="ml-2">Français</span>
          </button>
          <button
            onClick={() => handleLanguageChange("ar")}
            className="flex items-center px-4 py-2 w-full text-left hover:bg-[#E1F5FB] transition-all duration-300"
          >
            <img src={SaFlag} alt="العربية" className="w-[24px] h-[24px]" />
            <span className="ml-2">العربية</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
